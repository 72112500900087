import React from 'react';
import { CircularProgress } from '@mui/material';

interface LoaderButtonProps {
  isLoading: boolean;
  text: string;
  disabled?: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const LoaderButton: React.FC<LoaderButtonProps> = (props) => {
  const { isLoading, text, disabled, className, type, onClick } = props;

  return (
    <button
      disabled={disabled || isLoading}
      className={className}
      type={type}
      onClick={onClick}
    >
      <b>
        {!isLoading ? (
          text
        ) : (
          <CircularProgress className="loadingFreebee" size={24} />
        )}
      </b>
    </button>
  );
};
