import axios from 'axios';
import config from 'src/configs';
import { authConfigs } from 'src/configs/auth';

const AUTH_API = axios.create({
  baseURL: config.AUTH_ENDPOINT
});

const accessToken = window?.localStorage?.getItem(
  authConfigs.storageTokenKeyName
);

const APP_API = axios.create({
  baseURL: config.AUTH_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`
  }
});

// Request parsing interceptors
AUTH_API.interceptors.request.use(
  async (config) => config,
  (error) => Promise.reject(error)
);

APP_API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if ([401, 403].includes(error.response?.status)) {
      window?.localStorage?.removeItem(authConfigs.storageTokenKeyName);
    }
    return Promise.reject(error);
  }
);

export { AUTH_API, APP_API };
