import { makeAutoObservable } from 'mobx';
import { createContext } from 'react';

class GeneralStore {
  isLoading: boolean = false;
  hasError?: string = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading(loading: boolean) {
    console.log(loading);
    this.isLoading = loading;
  }

  setError(error?: string) {
    this.hasError = error;
  }
}

export const generalStoreContext = createContext(new GeneralStore());
