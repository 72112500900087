import React, { FormEvent, useState } from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
  Input,
  IconButton,
  InputAdornment,
  FormHelperText,
  Button,
  Box,
  Typography
} from '@mui/material';
import { LoaderButton } from '../common/layout/LoaderButton';
import visibilityOff from '../../assets/icons/ic_visibility-off.svg';
import visibilityOn from '../../assets/icons/ic_visibility-on.svg';
import styles from '../../style/auth.module.css';
import { useNavigate } from 'react-router';
import { useAuth } from '../../hooks/useAuth';
import { color, theme } from 'src/theme';

const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth()
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      await login({ email, password, deviceId: 'webapp' });
    } catch (e) {
      console.log(e);
      setLoginError('Benutzername und Passwort stimmen nicht überein');
    } finally {
      setIsLoading(false);
    }
  };


  return <Grid container alignItems="flex-end" justifyContent="center">
    <form
      onSubmit={async (e) => await handleSubmit(e)}
      className={styles.form}
    >
      <Grid item xs={12}>
        <Typography variant='h5' sx={{
          fontWeight: 'bold',
          lineHeight: '28px',
          letterSpacing: '0.3px',
          fontSize: '25px',
          color: '#121212',
          textAlign: 'center',
          mb: 4
        }}>Login</Typography>
      </Grid>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <FormControl className={styles.emailLabel}>
          <InputLabel>Email</InputLabel>
          <Input
            error={loginError.length > 0}
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl className={styles.passwordLabel}>
          <InputLabel>Password</InputLabel>
          <Input
            error={loginError.length > 0}
            aria-describedby="password-error-text"
            type={showPassword ? 'text' : 'password'}
            onChange={(e) => setPassword(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword()}
                  className={styles.noPadding}
                >
                  {showPassword ? (
                    <img src={visibilityOff} alt="visibility on" />
                  ) : (
                    <img src={visibilityOn} alt="visibility off" />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText
            id="password-error-text"
            error
            style={{ margin: '0' }}
          >
            {loginError}
          </FormHelperText>
        </FormControl>
        <Grid container item justifyContent='flex-end'>
          <Button variant='text' size='small' sx={{ [theme.breakpoints.only('xs')]: { color: color.black }, mt: 1 }} onClick={() => navigate('/reset-password')}>
            Passwort Vergessen?
          </Button>
        </Grid>
      </Grid>
      <Box className={styles.buttonDiv}>
        <LoaderButton
          className={styles.loginButton}
          disabled={!(email.length > 0 && password.length > 0)}
          isLoading={isLoading}
          type="submit"
          text="Anmelden"
        ></LoaderButton>
      </Box>
    </form>
  </Grid>;
};

export default Login;
