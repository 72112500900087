import React, { useContext } from 'react';
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from 'src/assets/logo/nano_lang_black@2x.png';
import { Link } from 'react-router-dom';
import { pages, PageTypes } from '../Pages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from 'src/hooks/useAuth';
import { generalStoreContext } from '../stores/generalStore';
import LoadingScreen from '../components/common/LoadingScreen';

const drawerWidth = 240;

interface LayoutProps {
  window?: () => Window;
  children: React.ReactNode;
}

export const MainLayout = (
  props: LayoutProps
) => {
  const { window, children } = props;
  const generalStore = useContext(generalStoreContext);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { user, logout, loading } = useAuth()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = async () => {
    user && logout({ userId: user.id, deviceId: 'webapp' });
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {pages
          .filter((p) => p.type === PageTypes.BILLING)
          .map((p) => (
            <Link to={p.value} key={p.value}>
              <ListItem button>
                <ListItemIcon>
                  <FontAwesomeIcon icon={p.icon} size="lg" />
                </ListItemIcon>
                <ListItemText primary={p.title} />
              </ListItem>
            </Link>
          ))}
      </List>
      <Divider />
      <List>
        <ListItem button key="logout" onClick={async () => await handleLogout()}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faSignOutAlt} size="lg" />
          </ListItemIcon>
          <ListItemText primary={'Logout'} />
        </ListItem>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const isLoading = generalStore.isLoading || loading;

  return (
    <Box id="app" sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` }
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            <Link to="/invoices">
              <img src={logo} alt="Logo" height="16px" />
            </Link>
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth
            }
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth
            }
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` }
        }}
      >
        <Toolbar />
        {isLoading ? <LoadingScreen /> : children}
      </Box>
    </Box>
  );
};
