import {
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Invoice from '../../models/Invoice';
import { invoiceStore } from '../../stores/invoiceStore';
import moment from 'moment';

export enum TableHeaderTypes {
  INVOICE_NUMBER = 'Rechnungsnummer',
  NAME = 'Name',
  DATE = 'Datum',
  SUM = 'Summe',
  ACTIONS = 'Aktionen'
}

export default function Invoices() {
  const [invoices, setInvoices] = useState<Invoice[] | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!invoices) {
      fetchInvoices();
    }
  }, [invoices]);

  async function fetchInvoices() {
    try {
      setLoading(true);
      const invoices = await invoiceStore.getInvoices();
      setInvoices(invoices);
    } catch (e) {
      console.error(e);
      setInvoices([]);
    } finally {
      setLoading(false);
    }
  }

  const makeHead = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell>{TableHeaderTypes.INVOICE_NUMBER}</TableCell>
          <TableCell>{TableHeaderTypes.NAME}</TableCell>
          <TableCell>{TableHeaderTypes.DATE}</TableCell>
          <TableCell>{TableHeaderTypes.SUM}</TableCell>
          <TableCell>{TableHeaderTypes.ACTIONS}</TableCell>
        </TableRow>
      </TableHead>
    );
  };

  const makeBody = () => {
    return (
      <TableBody>
        {invoices &&
          invoices.map((invoice) => (
            <TableRow key={invoice.invoiceId} tabIndex={-1}>
              <TableCell align="left">{invoice.invoiceNumber}</TableCell>
              <TableCell align="left">
                {invoice.givenname} {invoice.lastname}
              </TableCell>
              <TableCell align="left">
                {moment(invoice.createdAt).format('DD.MM.YYYY')}
              </TableCell>
              <TableCell align="left">€ {invoice.amount / 100}</TableCell>
              <TableCell>
                <a
                  href={
                    'data:application/pdf;base64,' + invoice.invoiceAsBase64
                  }
                  download={invoice.invoiceNumber}
                >
                  Rechnung herunterladen
                </a>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    );
  };

  if (loading) {
    return (
      <Grid container justifyContent="center" style={{ marginTop: '2em' }}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid container justifyContent="center" style={{ marginTop: '2em' }}>
      <Grid item xs={12}>
        <Paper>
          {invoices && invoices.length === 0 ? (
            <>Noch keine Rechnungen.</>
          ) : (
            <TableContainer component={Paper}>
              <Table>
                {makeHead()}
                {makeBody()}
              </Table>
            </TableContainer>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}
