import React, { useState } from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  FormHelperText
} from '@mui/material';
import styles from 'src/style/auth.module.css';
import visibilityOff from 'src/assets/icons/ic_visibility-off.svg';
import visibilityOn from 'src/assets/icons/ic_visibility-on.svg';

type Props = {
  code: string,
  error: string,
  onPasswordChange: (value: string) => void
  onCodeChange: (value: string) => void
}

const ResetPassword = (props: Props) => {
  const { code, error, onPasswordChange, onCodeChange } = props
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);


  return <Grid item xs={12} sx={{ mb: 2 }}>
    <FormControl className={styles.emailLabel} sx={{ mb: 2 }}>
      <InputLabel>Code</InputLabel>
      <Input
        error={!!error}
        type="text"
        value={code}
        onChange={(e) => onCodeChange(e.target.value)}
      />
    </FormControl>
    <FormControl className={styles.passwordLabel}>
      <InputLabel>Password</InputLabel>
      <Input
        error={!!error}
        aria-describedby="password-error-text"
        type={showPassword ? 'text' : 'password'}
        onChange={(e) => onPasswordChange(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => handleClickShowPassword()}
              className={styles.noPadding}
            >
              {showPassword ? (
                <img src={visibilityOff} alt="visibility on" />
              ) : (
                <img src={visibilityOn} alt="visibility off" />
              )}
            </IconButton>
          </InputAdornment>
        }
      />
      <FormHelperText
        id="password-error-text"
        error
        style={{ margin: '0' }}
      >
        {error}
      </FormHelperText>
    </FormControl>
  </Grid>
};

export default ResetPassword;
