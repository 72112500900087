import { faFileInvoice } from '@fortawesome/free-solid-svg-icons';

export enum PageTypes {
  BILLING
}

export const pages = [
  {
    title: 'Rechnungen',
    description: 'Hier siehst du deinen Rechnungen',
    value: 'invoices',
    icon: faFileInvoice,
    type: PageTypes.BILLING
  }
];
