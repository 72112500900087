import React from 'react';
import { App } from './App';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

/* mobx */

import { configure } from 'mobx';
import promiseFinally from 'promise.prototype.finally';

import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { AuthProvider } from './context/AuthContext';

promiseFinally.shim();
configure({ enforceActions: 'never' });

// Firebase Analytics
const firebaseConfig = {
  apiKey: 'AIzaSyBfZDHFNaBA4exwCUWRTNrEt44Nv108wqI',
  authDomain: 'nano-admi.firebaseapp.com',
  projectId: 'nano-admi',
  storageBucket: 'nano-admi.appspot.com',
  messagingSenderId: '490540140148',
  appId: '1:490540140148:web:55d5bd5d9c25f017bf3321',
  measurementId: 'G-NS0CSXNJZW'
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// check if user disabled analytics
const analytics = getAnalytics(firebaseApp);

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

root.render(
  <Router>
    <AuthProvider>
      <App />
    </AuthProvider>
  </Router>
);

export { analytics };
