import { action } from 'mobx';
import { APP_API } from 'src/helpers/api';
import { authConfigs } from 'src/configs/auth'

class InvoiceStore {
  @action async getInvoices() {
    const accessToken = window?.localStorage?.getItem(authConfigs.storageTokenKeyName)
    const config = {
      headers: {
        Authorization: accessToken,
      },
    };

    const response = await APP_API.get('/user/invoices?scope=1', config);
    return response.data
  }
}

const invoiceStore: InvoiceStore = new InvoiceStore();

export { invoiceStore };