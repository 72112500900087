// ** React Imports
import { createContext, useEffect, useState, ReactNode } from 'react'

// ** Config
import { authConfigs } from 'src/configs/auth'

// ** Types
import {
  AuthValuesType,
  LoginParams,
  ErrCallbackType,
  UserDataType,
  ResetParams,
  LogoutParams
} from './types'

import { AUTH_API } from 'src/helpers/api'
import { useNavigate } from 'react-router-dom';
import { allRoutes } from 'src/routes';

// ** Defaults
const defaultProvider: AuthValuesType = {
  user: null,
  loading: true,
  isAuthenticated: false,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  requestPassword: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
}

export const AUTH_SESSION_KEY = 'connect.sid'

const AuthContext = createContext(defaultProvider)

type Props = {
  children: ReactNode
}

const AuthProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const [user, setUser] = useState<UserDataType | null>(defaultProvider.user)
  const accessToken = window?.localStorage?.getItem(authConfigs.storageTokenKeyName)
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!accessToken)
  const [loading, setLoading] = useState<boolean>(defaultProvider.loading)

  useEffect(() => {
    const publicRoutes = allRoutes.filter(item => item.public).map(r => r.path)
    if (!loading) {
      if (isAuthenticated && publicRoutes.includes(window.location.pathname)) {
        navigate('/invoices')
      }
      if (!isAuthenticated && !publicRoutes.includes(window.location.pathname)) {
        navigate('/login')
      }
    }
  }, [isAuthenticated, navigate, loading])

  useEffect(() => {
    const initAuth = async (): Promise<void> => {
      const accessToken = window?.localStorage?.getItem(authConfigs.storageTokenKeyName)
      const config = {
        headers: {
          Authorization: accessToken,
        },
      };

      setLoading(true)
      AUTH_API
        .get(`${authConfigs.meEndpoint}?scope=1`, accessToken ? config : {})
        .then(async (response) => {
          response.data.accessToken && window?.localStorage?.setItem(authConfigs.storageTokenKeyName, response.data.accessToken)
          setLoading(false)
          setIsAuthenticated(true)
          setUser({ id: response.data.userId })
        })
        .catch(() => {
          window?.localStorage?.removeItem(authConfigs.storageTokenKeyName)
          setUser(null)
          setIsAuthenticated(false)
          setLoading(false)
          const publicRoutes = [
            'login',
            'register',
            'reset-password',
            'verify-email',
            'confirm',
          ]
          if (!publicRoutes.some((path) => window.location.pathname.includes(path)) && window.location.pathname !== 'login') {
            navigate('/login', {
              replace: true
            })
          }
        })
    }

    initAuth()
  }, [navigate])

  const handleLogin = async (
    params: LoginParams,
    errorCallback?: ErrCallbackType,
  ) => {
    AUTH_API
      .post(authConfigs.loginEndpoint, params)
      .then(async (response) => {
        window?.localStorage?.setItem(authConfigs.storageTokenKeyName, response.data.accessToken)
        setUser({ id: response.data.userId })
        setIsAuthenticated(true)
        if (response.status === 426) {
          return navigate('/request-password?source=login')
        }
        if (response.data.status === 'unconfirmed') {
          window?.localStorage?.removeItem(authConfigs.storageTokenKeyName)
          const redirectURL = '/verify-email'
          return navigate(redirectURL as string)
        }
      })
      .catch((err) => {
        if (errorCallback) errorCallback(err)
      })
  }

  const handleLogout = async (params: LogoutParams) => {
    if (isAuthenticated) {
      setUser(null)
      setIsAuthenticated(false)
      AUTH_API
        .post(authConfigs.logoutEndpoint, params)
        .then(() => {
          document.cookie = authConfigs.storageTokenKeyName + '=; Max-Age=0'
          window?.localStorage?.removeItem(authConfigs.storageTokenKeyName)
        })
        .catch((err) => console.error(err))
    }
  }

  const handleRequestPassword = async (params: { email: string }) => {
    AUTH_API.post(authConfigs.requestPasswordEndpoint, params)
  }

  const handleResetPassword = async (params: ResetParams) => {
    AUTH_API.post(authConfigs.resetPasswordEndpoint, params)
      .then(async (response) => {
        window?.localStorage?.setItem(authConfigs.storageTokenKeyName, response.data.accessToken)
        setIsAuthenticated(true)
      })
  }

  const values = {
    user,
    loading,
    isAuthenticated,
    login: handleLogin,
    logout: handleLogout,
    requestPassword: handleRequestPassword,
    resetPassword: handleResetPassword,
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
