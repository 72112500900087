import { createTheme } from '@mui/material/styles';

export const color = {
  nanoPurple: '#a890fe',
  black: '#000',
  white: '#FFF'
};

export const theme = createTheme({
  palette: {
    primary: {
      main: color.nanoPurple,
      contrastText: color.white
    },
    secondary: {
      main: color.black,
      contrastText: color.white
    }
  },
  typography: {
    fontFamily: ['DMSans'].join(','),
    h5: {
      fontFamily: ['Bricolage Grotesque', 'DMSans'].join(','),
      fontWeight: 600,
      fontSize: '20px'
    },
    h6: {
      fontFamily: ['Bricolage Grotesque', 'DMSans'].join(','),
    },
  }
});
