import React, { ReactNode } from 'react';
import { Grid } from '@mui/material';
import styles from 'src/style/auth.module.css';
import logo from 'src/assets/logo/logo_square_simple.png'

const UserLayout = (props: { children?: ReactNode }) => {
  const { children } = props

  return <Grid
    id="app"
    className={styles.container}
    container
    alignItems="center"
    alignContent="space-around"
  >
    <Grid item xs={12} className={styles.logoContainer}>
      <img
        src={logo}
        alt="Nano Logo"
        className={styles.logo}
        width="220"
      />
    </Grid>
    {children}
  </Grid>;
};

export default UserLayout;
