import { CircularProgress, Grid } from '@mui/material';
import React from 'react';

export default function LoadingScreen() {
  return (
    <Grid
      container
      sx={{
        height: 'calc(100vh - 64px)'
      }}
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Grid>
  );
}
