import React from 'react';
import {
  useRoutes,
} from 'react-router-dom';
import './App.css';
import { theme } from './theme';
import { ThemeProvider } from '@mui/material/styles';
import { allRoutes } from './routes';
import UserLayout from './layouts/UserLayout';
import { MainLayout } from './layouts/Layout';
import { useAuth } from './hooks/useAuth';

export const App = () => {
  const { isAuthenticated } = useAuth()
  const routes = useRoutes(allRoutes);

  const Layout = isAuthenticated ? MainLayout : UserLayout

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        {routes}
      </Layout>
    </ThemeProvider>
  );
};
