import Login from "src/components/auth/Login";
import RequestPassword from "src/components/auth/RequestPassword";
import Invoices from "src/components/invoices/Invoices";

export const allRoutes = [
  {
    path: '/login',
    element: <Login />,
    public: true,
  },
  {
    path: '/reset-password',
    element: <RequestPassword />,
    public: true,
  },
  {
    path: '/',
    element: <Invoices />,
    public: false,
  },
  {
    path: '/invoices',
    element: <Invoices />,
    public: false,
  },

]