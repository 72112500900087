import React, { FormEvent, useState } from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
  Input,
  Box,
  Typography,
  Alert,
} from '@mui/material';
import { LoaderButton } from '../common/layout/LoaderButton';
import styles from 'src/style/auth.module.css';
import { useAuth } from 'src/hooks/useAuth';
import ResetPassword from './ResetPassword';
import { useLocation } from 'react-router';

const RequestPassword: React.FC = () => {
  const location = useLocation();
  const { requestPassword, resetPassword } = useAuth()
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isRequested, setRequested] = useState(false);
  const [password, setPassword] = useState('');

  const searchParams = new URLSearchParams(location.search);
  const scope = searchParams.get('scope');

  const handlePasswordChange = (value: string) => setPassword(value);
  const handleCodeChange = (value: string) => setCode(value);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      if (!isRequested) {
        try {
          await requestPassword({ email });
          setRequested(true)
        } catch (error) {
          setRequested(false)
        }
      }
      isRequested && await resetPassword({ code, email, password, deviceId: 'webapp' });
    } catch (e) {
      console.log(e);
      setError('Etwas ist schief gelaufen');
    } finally {
      setIsLoading(false);
    }
  };


  return <Grid container alignItems="flex-end" justifyContent="center">
    <form
      onSubmit={async (e) => await handleSubmit(e)}
      className={styles.form}
    >
      <Grid item xs={12}>
        <Typography variant='h5' sx={{
          fontWeight: 'bold',
          lineHeight: '28px',
          fontSize: '25px',
          letterSpacing: '0.3px',
          color: '#121212',
          textAlign: 'center',
          mb: 2
        }}>Passwort Zurücksetzten</Typography>
        <Typography variant='body2' sx={{ mb: 3, textAlign: 'center' }}>Mach dir nichts draus, passiert den Besten! Sag uns bitte deine E-Mail-Adresse und wir senden dir einen Code zum Zurücksetzen.</Typography>
      </Grid>
      {scope === 'login' && <Grid item xs={12} sx={{ mb: 2 }}>
        <Alert severity="warning">Eine Passwortrücksetzung erforderlich ist</Alert>
      </Grid>}
      <Box>
        {!isRequested && <Grid item xs={12} sx={{ mb: 2 }}>
          <FormControl className={styles.emailLabel}>
            <InputLabel>Email</InputLabel>
            <Input
              error={!!error}
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
        </Grid>}
        {isRequested && <ResetPassword code={code} error={error} onCodeChange={handleCodeChange} onPasswordChange={handlePasswordChange} />}
      </Box>
      <Box className={styles.buttonDiv}>
        <LoaderButton
          className={styles.loginButton}
          disabled={!email}
          isLoading={isLoading}
          type="submit"
          text="Weiter"
        ></LoaderButton>
      </Box>
    </form>
  </Grid>;
};

export default RequestPassword;
